import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { BlockAdminCard, Dropdown } from "../../components";
import { INewsItem, Status } from "../../types";
import { useHttp } from "../../hooks/useHttp";
import { INewsItemAPI, IPublishedItemAPI } from "../../types/api/admin";
import { useGlobalState } from "../../store";
import { getImgUrl } from "../../utils/getImgUrl";
import { useLocation } from 'react-router-dom'
import Multiselect from "../../components/Multiselect/Multiselect";
import {Button} from "../../components/shared/Button/Button";
import useDebounce from "../../hooks/useDebounce";


export const Admin: React.FC = () => {
  const location = useLocation()
  const token = sessionStorage.getItem("token");
  const [isLogin, setLogin] = useGlobalState("isLogin");
  const [newsItems, setNewsItems] = useState<Array<INewsItem>>([]);
  const [declinedItems, setDeclined] = useState<Array<any>>([]);
  const [publishedItems, setPublished] = useState<Array<any>>([]);
  const [endData, setDataEnd] = useState({
    newsItems: false,
    declinedItems: false,
    publishedItems: false
  });
  const [domainOptions, setDomainOptions] = useState<{ value: string; name: string }[]>([])
  const [domainsFilter, setDomainsFilter] = useState<string[]>([]);
  const [tagFilter, setTagFilter] = useState<string | undefined>(undefined)
  const [tagDropdownOptions, setTagDropdownOptions] = useState<{name:string, id:string, domain:string}[]>([]);
  const [searchText, setSearchText] = useState<string>('')

  const debouncedText = useDebounce(searchText, 500);



  const history = useNavigate();
  const { request } = useHttp();



  const fetchDomains = useCallback(async () => {
    const domainsRes = await request({
      path: "/domains/",
      method: "GET",
    })
    const opts = domainsRes?.map((d: any) => ({ name: d.title, value: String(d.id) }))
    setDomainOptions(opts)
  }, [])

  const fetchDropdownOptions = async ()=>{
    const options = await request({
      path:"/tabs"
    })
    setTagDropdownOptions(options.map((el:any) => ({...el})));
  }


  useEffect(() => {
    fetchDomains()
    fetchDropdownOptions()
  }, []);


  useEffect(()=>{

  },[debouncedText])



  const getDeclined = async (status?: Status, offset?: number, q?:string) => {
    const respDeclined: Array<IPublishedItemAPI> | null = await request({
      path: "/declined/",
      method: "GET",
      query: {
        offset: offset ?? 0,
        q:q||"",
      }
    });
    if (respDeclined) {
      const declinedItems = respDeclined.map((item) => ({
        id: item.id,
        img: getImgUrl(item.media_link),
        tag: [],
        title: item.title,
        description: "",
        author: item.by,
        date: item.date,
        mainNews: false,
      }));
      if (status === 'offset') {
        if (!declinedItems.length) return setDataEnd((prev) => ({ ...prev, declinedItems: true }))
        setDeclined(prev => [...prev, ...declinedItems]);
      } else setDeclined(declinedItems);
    }
  };










  const getNews = async (status?: Status, offset?: number, q?:string) => {
    const params: any = {
      offset: offset ?? 0
    }
    if(tagFilter) params.tag = tagFilter
    const respNews: Array<INewsItemAPI> | null = await request({
      path: `/news/`,
      method: "GET",
      query: {
        ...params,
        q,
      }
    });

    if (respNews) {
      const newItems = respNews.map((item) => ({
        id: item.id,
        img: getImgUrl(item.media_link),
        tag: [],
        title: item.title,
        description: "",
        author: item.by,
        date: item.date,
        mainNews: false,
      }));


      if (status === 'offset') {
        if (!newItems.length) return setDataEnd((prev) => ({ ...prev, newsItems: true }))
        setNewsItems(prev => [...prev, ...newItems]);
      } else setNewsItems(newItems);
    }
  };


  const getPublished = async (status?: Status, offset?: number, q?:string) => {
    const params:any = {
      offset: offset ?? 0,
      domain: domainsFilter.join(",")
    }
    if(tagFilter){
      params.tab = tagFilter;
    }
    const respPublished: Array<IPublishedItemAPI> | null = await request({
      path: "/published/",
      method: "GET",
      query: {
        ...params,
        q,
      },
    });
    if (respPublished) {
      const newPublished = respPublished.map((item) => ({
        id: item.id,
        img: getImgUrl(item.media_link),
        tag: [],
        title: item.title,
        description: "",
        author: item.by,
        domain: item.domain,
        date: item.date,
        mainNews: false,
      }));
      if (status === 'offset') {
        if (!newPublished.length) return setDataEnd((prev) => ({ ...prev, publishedItems: true }))
        setPublished(prev => [...prev, ...newPublished]);
      } else setPublished(newPublished);
    }
  };


  // useEffect(() => {
  //   if (token || (token && isLogin) && location.pathname === '/admin') { //Fetch every time user visits this URL
  //     getNews();
  //     getDeclined();
  //     getPublished();
  //   }
  // }, [isLogin, location]);

  useEffect(()=>{
    getPublished(undefined, undefined, debouncedText);
    getNews(undefined, undefined, debouncedText);
    getDeclined(undefined, undefined, debouncedText);

    setDeclined([]);
    setPublished([]);
    setNewsItems([]);
  },[tagFilter, domainsFilter, debouncedText])



  const onNewItem = async (status: Status, offset?: number) => {
    await getNews(status, offset)
    if (status === "decline") await getDeclined();
    if (status === "publish") await getPublished();
  };

  const handleClearFilters = ()=>{
    setDomainsFilter([]);
    setSearchText("")
    setTagFilter(undefined);
  }
  const onDeclinedNews = async (status: Status, offset?: number) => {
    await getDeclined(status, offset);
    if (status === "restore") await getNews();
  };

  const onPublished = async (status: Status, offset?: number) => {
    if (status === "offset") await getPublished(status, offset);
    if (status === "publish") await getPublished();
  };




  const getTagOptions = useCallback(()=>{
    return tagDropdownOptions.map(tag =>{
      const domainName = domainOptions.find(domain => domain.value === tag.domain.toString())
      if(!domainName) return {name:tag.name, value:tag.id}
      return {name:`${tag.name} - ${domainName.name}`, value:tag.id}
    })
  },[tagDropdownOptions, domainOptions])
  return (
      <div className="admin-container">
        <div className="admin-buttons">
          <div className="admin-header-group">
            <div className="search">
              <input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" type="text" className="search__input" />
            </div>
            <Multiselect label="Domains filter" value={domainsFilter} options={domainOptions} handleChange={setDomainsFilter} />
            <Dropdown label="Tag filter" value={tagFilter || ""} handleChange={setTagFilter} options={[...getTagOptions(), {name:"", value:""}]} />

            <Button onClick = {handleClearFilters}>Clear Filters</Button>
          </div>
          <div className="admin-header-group">
            <div
                onClick={() => history({ pathname: "/admin?popup=twitter-widgets" })}
                className="buttons_monitoring"
            >
              Twitter widgets
            </div>
            {/* <div
            onClick={() => history({ pathname: "/admin?popup=twitter" })}
            className="buttons_monitoring"
        >
          Twitter accounts
        </div> */}
            <div
                onClick={() => history({ pathname: "/admin?popup=monitoring" })}
                className="buttons_monitoring"
            >
              Monitoring sources
            </div>
            <div
                onClick={() => history({ pathname: "/admin?popup=pendingItems " })}
                className="buttons_monitoring"
            >
              Pending items
            </div>
            <div
                onClick={() => history({ pathname: "/admin/create" })}
                className="buttons_create"
            >
              + Create
            </div>
          </div>
        </div>
        <div className="adminNews">
          <div className="adminNews__block">
            <div className="adminNews__block_title">New item</div>
            <BlockAdminCard
                items={newsItems}
                status="newItem"
                handleClick={(_, status) => onNewItem(status)}
                featchItems={(offset) => onNewItem('offset', offset)}
                hasMore={endData.newsItems}
            />
          </div>
          <div className="adminNews__block">
            <div className="adminNews__block_title">Declined news</div>
            <BlockAdminCard
                items={declinedItems}
                status="declinedNews"
                handleClick={(_, status) => onDeclinedNews(status)}
                featchItems={(offset) => onDeclinedNews('offset', offset)}
                hasMore={endData.declinedItems}
            />
          </div>
          <div className="adminNews__block">
            <div className="adminNews__block_title">Published news</div>
            <BlockAdminCard
                items={publishedItems}
                status="publishedNews"
                handleClick={(_, status) => onPublished(status)}
                featchItems={(offset) => onPublished('offset', offset)}
                hasMore={endData.publishedItems}
            />
          </div>
        </div>
      </div>
  );
};
