import React, { useCallback, useEffect, useMemo, useState } from "react"
import publish from "../../assets/img/publish.svg";
import { useHttp } from "../../hooks/useHttp";
import { ModalWrapper } from "../modal/ModalWrapper";
import Multiselect from "../Multiselect/Multiselect";




interface IProps {
    onSubmit: (domains: number[]) => void
    children: React.ReactNode
    isFetching: boolean
}


const PublishComponent = React.memo(({ onSubmit, children, isFetching }: IProps) => {
    const { request } = useHttp();
    const [opened, setOpened] = useState<boolean>(false)
    const [selected, setSelected] = useState<any[]>([])
    const [domainOptions, setDomainOptions] = useState<{ value: string, name: string }[]>([])



    const handleFetchDomains = useCallback(async () => {
        const domainsRes = await request({
            path: "/domains/",
            method: "GET",
        })
        const opts = domainsRes?.map((d: any) => ({ value: String(d.id), name: d.domain_regex })) || []
        setDomainOptions(opts)
    }, [])



    useEffect(() => {
        if (opened) handleFetchDomains()
    }, [handleFetchDomains, opened])



    return <>
        {opened && <ModalWrapper>
            <div className="publish-modal">
                {!isFetching && <>
                    <Multiselect label="Select domain" value={selected} options={domainOptions} handleChange={setSelected} />
                    <div className="publish-modal__box">
                        <span
                            onClick={() => setOpened(false)}
                            className="btnAdmin red publish-modal__btn"
                        >
                            Close
                        </span>
                        <span
                            onClick={() => onSubmit(selected.map(d => Number(d)))}
                            className="btnAdmin green publish-modal__btn"
                        >
                            Publish <img src={publish} alt="publish" />
                        </span>
                    </div>
                </>}
                {isFetching && <p>Loading...</p>}
            </div>
        </ModalWrapper>}
        <span onClick={() => setOpened(true)}>
            {children}
        </span>
    </>
})


export default PublishComponent